import React, { useEffect, useState } from "react";
import {
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import axios from "axios";
import { DropzoneArea } from "material-ui-dropzone";
import { useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { BASE_URL } from "src/lib/lib.uri";
import MuiDataTables from "src/components/molecules/MuiDatatables/index";
import Page from "src/components/molecules/Page";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",

  boxShadow: 24,
  borderRadius: 5,
  p: 4,
};
const list = ["Document", "Date Time", "JP"];
const listAll = ["Name", "JP", "Done"];

export default function PageMonitorJamPelajaran() {
  const [open, setOpen] = React.useState(false);
  const [nameDoc, setNameDoc] = React.useState();
  const [pathDoc, setPathDoc] = React.useState();
  const [userAlreadyUploaded, setUserAlreadyUploaded] = React.useState([]);
  const [userWantToGet, setUserWantToGet] = React.useState("");
  const [documentUser, setDocumentsUser] = React.useState();
  const [documentUserAll, setDocumentsUserAll] = React.useState();
  const [jpUser, setJpUser] = React.useState();

  useEffect(() => {
    axios
      .get(`${BASE_URL}api/arsiparis/workshops/list/uploaded`)
      .then((resp) => {
        console.log(resp.data);
        setUserAlreadyUploaded(resp.data);
      });
    axios.get(`${BASE_URL}api/arsiparis/workshops/all`).then((resp) => {
      setDocumentsUserAll(resp.data);
    });
    return () => {};
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const options = {
    selectableRows: true,
    onRowClick: (rowData, rowState) => {
      setNameDoc(rowData[0]);
      setPathDoc(rowData[1]);
      handleOpen();
    },
  };

  const handleChange = (e) => {
    const username = e.target.value;
    setUserWantToGet(username);
    axios
      .get(`${BASE_URL}api/arsiparis/workshops/user/${username}`)
      .then((resp) => {
        let documentWorkshop = [];
        resp.data.value.forEach((item) => {
          documentWorkshop.push([item.title, item.datetime, item.jampelajaran]);
        });
        let jp = resp.data.value.reduce((accumulator, current) => {
          const year = new Date(current.datetime).getFullYear();
          if (year === 2023) {
            return accumulator + parseInt(current.jampelajaran, 10);
          }
          return accumulator;
        }, 0);
        setJpUser(jp);
        console.log(documentWorkshop);
        setDocumentsUser(documentWorkshop);
      });
  };

  const downloadFile = () => {
    axios({
      url: `${BASE_URL}api/arsiparis/documents/download/${userWantToGet}/Workshop_${nameDoc}`,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.pdf");
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <Page title="Data | Juanda">
      <Container maxWidth="xl" style={{ padding: 10 }}>
        <FormControl fullWidth style={{ marginBottom: 10 }}>
          <InputLabel id="demo-simple-select-label">User</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={userWantToGet}
            label="User"
            onChange={handleChange}
          >
            {userAlreadyUploaded.map((userDocument, index) => (
              <MenuItem key={index} value={userDocument.username}>
                {userDocument.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box sx={{ p: 3, boxShadow: 2, mb: 2 }} textAlign={"center"}>
          <Typography variant="h4">{`Total JP of this user is ${jpUser}/hour`}</Typography>
        </Box>
        <div>
          <MUIDataTable
            title="DATA ARSIP"
            data={documentUser}
            columns={list}
            options={options}
          />
        </div>
        <div style={{ marginTop: 5 }}>
          <MUIDataTable
            title="DATA ALL"
            data={documentUserAll}
            columns={listAll}
          />
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div>
              <h1>DOWNLOAD DOCUMENT</h1>
              <div>
                <button onClick={downloadFile}>Download Existing File</button>
              </div>
            </div>
          </Box>
        </Modal>
      </Container>
    </Page>
  );
}
MuiDataTables.propTypes = {
  dataSet: PropTypes.array,
};
