import React from "react";
import { Container, Typography } from "@material-ui/core";
import { Box, Grid, Stack, Card, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import Page from "../components/molecules/Page";

const useStyles = makeStyles({
  profileWrapper: {
    background: "light",
    borderRadius: 3,
    boxShadow: "0 3px 1px 1px rgba(0,0,0, .3)",
    color: "white",
    padding: "0 30px",
    borderStyle: "solid",
    borderWidth: 0.1,
  },
  fotoProfile: {
    // backgroundColor: 'red'
  },
  bioData: {},
  imgProfile: {
    borderRadius: 20,
  },
  imgWrapper: {
    padding: 10,
  },
  labelListWrapper: {
    height: "40px",
    marginBottom: 10,
  },
});

function ListProfile({ classes, label, value }) {
  return (
    <Paper
      elevation={3}
      sx={{ p: 1, flexGrow: 1 }}
      className={classes.labelListWrapper}
    >
      <Grid item container direction="row">
        <Grid item className={classes.labelList} md={5}>
          <Typography>{label}</Typography>
        </Grid>
        <Grid item className={classes.valueList} md={1}>
          :
        </Grid>
        <Grid item className={classes.valueList} md={6}>
          {value}
        </Grid>
      </Grid>
    </Paper>
  );
}

export default function PageProfile() {
  const profile = useSelector((store) => store.auth.user);

  const classes = useStyles();
  return (
    <Page title="Dashboard | Juanda">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Profile Page</Typography>
        </Box>
        <Card>
          <Box sx={{ p: 3, pb: 1 }} dir="ltr">
            <Grid container spacing={3}>
              <Grid
                item
                container
                xs={12}
                md={4}
                xl={4}
                className={classes.fotoProfile}
              >
                <div className={classes.imgWrapper}>
                  <img
                    className={classes.imgProfile}
                    src="https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80"
                    alt="profile"
                  />
                </div>
              </Grid>
              <Grid
                item
                container
                xs={12}
                md={8}
                xl={8}
                flex
                className={classes.bioData}
                direction="column"
              >
                <ListProfile
                  classes={classes}
                  label="Unit"
                  value={profile.unit}
                />
                <ListProfile
                  classes={classes}
                  label="Nama"
                  value={profile.name}
                />
                <ListProfile
                  classes={classes}
                  label="Email"
                  value={profile.email}
                />
                <ListProfile
                  classes={classes}
                  label="Contact"
                  value={profile.numberphone}
                />
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
