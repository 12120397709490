// routes
// theme
import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";
// components
import ScrollToTop from "./components/molecules/ScrollToTop";
// import Routers from './routes/index';
import Routers from "./routes/index";

// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeConfig>
      <ScrollToTop />
      <GlobalStyles />
      <Routers />
    </ThemeConfig>
  );
}
