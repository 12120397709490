import { Box, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { logoutUserAction } from "../../redux/actions/redux.action.auth";

function ButtonLoginNavbar() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();
  const logOutButton = () => {
    dispatch(logoutUserAction());
  };
  return (
    <Box sx={{ p: 2, color: "black" }}>
      <Button
        fullWidth
        color="inherit"
        variant="outlined"
        component={RouterLink}
        to="/login"
        onClick={logOutButton}
      >
        {isAuthenticated ? "Logout" : "Login"}
      </Button>
    </Box>
  );
}

export default ButtonLoginNavbar;
