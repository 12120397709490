// ----------------------------------------------------------------------

// eslint-disable-next-line import/no-unresolved
import { BASE_PATH } from 'src/lib/lib.uri';

const account = {
  displayName: 'Guest',
  email: 'guest',
  photoURL: `${BASE_PATH}/static/mock-images/avatars/avatar_default.jpg`
};

export default account;
