/* eslint-disable react/jsx-key */
// material
import {
  Box,
  Grid,
  Container,
  Typography,
  Card,
  CardHeader,
} from "@mui/material";
import { useEffect, useState } from "react";
// components
import Page from "../components/molecules/Page";
import { useSelector } from "react-redux";
// sections
import AppWidgetSummary from "../components/_dashboard/app/AppWidgetSummary";
import axios from "axios";
import { BASE_URL } from "src/lib/lib.uri";
import PlotlyChart from "src/components/molecules/plotlyChart";

export default function PageDashboard() {
  const user = useSelector((state) => state.auth.user);
  const [workhsop, setWorkshop] = useState([]);
  const [documentUser, setDocumentUser] = useState([]);
  const [jp, setJp] = useState(0);
  const [workshopUsers, setWorkshopUsers] = useState([]);
  const [userAlready20Jp, setUserAlready20Jp] = useState([]);
  const [dataChart, setDataChart] = useState([]);

  useEffect(() => {
    axios
      .get(`${BASE_URL}api/arsiparis/workshops/user/${user.username}`)
      .then((resp) => {
        let data = resp.data.value;
        // Chart
        let totalJamPelajaranPerTahun = data.reduce((acc, item) => {
          const year = item.datetime.split("-")[0]; // Ambil tahun dari datetime
          const jampelajaran = parseInt(item.jampelajaran, 10); // Pastikan jampelajaran adalah angka

          if (!acc[year]) {
            acc[year] = jampelajaran; // Jika tahun belum ada di accumulator, tambahkan
          } else {
            acc[year] += jampelajaran; // Jika sudah ada, tambahkan jumlah jam pelajaran
          }

          return acc;
        }, {});
        if (!totalJamPelajaranPerTahun["2024"]) {
          totalJamPelajaranPerTahun["2024"] = 0.4;
        }
        console.log(totalJamPelajaranPerTahun)
        setDataChart(totalJamPelajaranPerTahun)

        // Other
        if (data.length > 0) {
          let jp = 0;
          data.forEach((datum) => {
            jp += Number(datum.jampelajaran);
          });
          setJp(jp);
        }
        setWorkshop(resp.data.value);
      });
  }, []);
  useEffect(() => {
    axios
      .get(`${BASE_URL}api/arsiparis/documents/user/${user.username}`)
      .then((resp) => {
        setDocumentUser(resp.data);
      });
  }, []);
  useEffect(() => {
    axios
      .get(`${BASE_URL}api/arsiparis/workshops/list/uploaded`)
      .then((resp) => {
        setWorkshopUsers(resp.data);
        setUserAlready20Jp(resp.data.filter(person => person.totalJampelajaran > 20).length)

      });
  }, []);

  return (
    <Page title="Dashboard | Juanda">
      <Container maxWidth="xl">
        <Box sx={{ p: 3, boxShadow: 2, mb: 2 }} textAlign={"center"}>
          <Typography variant="h4">Hi, Selamat Datang di DAWAI!</Typography>
          {!user.name ? (
            <Typography variant="h6">
              Silahkan masuk untuk melanjutkan.
            </Typography>
          ) : (
            user.name
          )}
        </Box>
        <Box sx={{ p: 5, boxShadow: 2 }} textAlign={"center"}>
          <Typography variant="h6" mb={2}>
            Your Document in Dawai Sistem
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <AppWidgetSummary
                title="Workhsop / JP"
                total={`${workhsop.length} / ${jp} (jam)`}
                icon={"ant-design:android-filled"}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <AppWidgetSummary
                title="Document Pribadi"
                total={documentUser.length}
                icon={"ant-design:android-filled"}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <AppWidgetSummary
                title="SPT Files"
                total={0}
                icon={"ant-design:android-filled"}
              />
            </Grid>

          </Grid>
        </Box>
        <Box sx={{ p: 5, boxShadow: 2 }} textAlign={"center"}>
          <Typography variant="h6" mb={2}>
            Your Progress
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12}>
              {dataChart &&
                <PlotlyChart dataPlot={dataChart}></PlotlyChart>
              }
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ p: 5, boxShadow: 2 }} textAlign={"center"}>
          <Typography variant="h6" mb={2}>
            All Users Document in Dawai Sistem
          </Typography>
          <Grid container spacing={3}>

            <Grid item xs={12} sm={6} md={6}>
              <AppWidgetSummary
                color="info"
                title="User Already Uploaded JP"
                total={Math.floor((workshopUsers.length * 100) / 43) + `% (${workshopUsers.length})`}
                icon={"ant-design:android-filled"}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <AppWidgetSummary
                color="info"
                title="User 20 JP"
                total={Math.floor((userAlready20Jp * 100) / 43) + `% (${userAlready20Jp})`}
                icon={"ant-design:android-filled"}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
}
