import MUIDataTable from 'mui-datatables';
import React from 'react';
import PropTypes from 'prop-types';

const MuiDataTables = ({ dataSet, title, columns }) => {
  const options = {
    selectableRows: false
  };

  return <MUIDataTable title={title} data={dataSet} columns={columns} options={options} />;
};
MuiDataTables.propTypes = {
  dataSet: PropTypes.array,
  title: PropTypes.string,
  columns: PropTypes.array
};
export default MuiDataTables;
