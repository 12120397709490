import React, { useEffect, useState } from "react";
import { Container } from "@mui/material";
import MUIDataTable from "mui-datatables";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import axios from "axios";
import { DropzoneArea } from "material-ui-dropzone";
import { useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { BASE_URL } from "../lib/lib.uri";
import MuiDataTables from "../components/molecules/MuiDatatables/index";
import Page from "../components/molecules/Page";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",

  boxShadow: 24,
  borderRadius: 5,
  p: 4,
};
const list = ["Document", "Description", "Category"];

export default function PageDocsUser() {
  const [open, setOpen] = React.useState(false);
  const [nameDoc, setNameDoc] = React.useState();
  const [pathDoc, setPathDoc] = React.useState();
  const [uploadFile, setUploadFile] = React.useState();
  const [documentsUser, setDocumentsUser] = React.useState();
  const [uploaded, setUploaded] = React.useState(false);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(`${BASE_URL}api/arsiparis/documents/data/table/${user.username}`)
        .then((resp) => {
          setDocumentsUser(resp.data);
        });
    };
    fetchData();
    return () => {};
  }, [uploaded]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const options = {
    selectableRows: true,
    onRowClick: (rowData, rowState) => {
      setNameDoc(rowData[0]);
      setPathDoc(rowData[1]);
      handleOpen();
    },
  };

  const downloadFile = () => {
    axios({
      url: `${BASE_URL}api/arsiparis/documents/download/${user.username}/${nameDoc}`,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.pdf");
      document.body.appendChild(link);
      link.click();
    });
  };
  const onFileChange = (event) => {
    setUploadFile(event.target.files[0]);
  };

  const onFileUpload = () => {
    const formData = new FormData();

    if (uploadFile !== undefined) {
      formData.append("file", uploadFile, uploadFile.name);
      formData.append("nameDoc", nameDoc);
      formData.append("username", user.username);
      formData.append("name", user.name);
      axios
        .post(`${BASE_URL}api/arsiparis/documents/input`, formData)
        .then((resp) => {
          console.log(resp.data);
          if (resp.data.status === "success") {
            alert(resp.data.message);
            setUploaded(!uploaded);
          } else {
            alert(resp.data.message);
          }
        });
    } else {
      alert("Choose a file!");
    }
  };
  const fileData = () => {
    if (uploadFile !== undefined) {
      return (
        <div>
          <h2>File Details:</h2>

          <p>File Name: {uploadFile.name}</p>

          <p>File Type: {uploadFile.type}</p>
        </div>
      );
    }
    return (
      <div>
        <br />
        <h4>Choose before Pressing the Upload button</h4>
      </div>
    );
  };
  return (
    <Page title="Data | Juanda">
      <Container maxWidth="xl" style={{ padding: 10 }}>
        <div>
          <MUIDataTable
            title="DATA ARSIP"
            data={documentsUser}
            columns={list}
            options={options}
          />
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div>
              <h1>UPLOAD DOCUMENT</h1>
              <div>
                <input type="file" onChange={onFileChange} />
                <button onClick={onFileUpload}>Upload!</button>
                <button onClick={downloadFile}>Download Existing File</button>
              </div>
              {fileData()}
            </div>
            {/* <DropzoneArea onChange={handleChange} /> */}
          </Box>
        </Modal>
      </Container>
    </Page>
  );
}
MuiDataTables.propTypes = {
  dataSet: PropTypes.array,
};
