import React, { useState } from "react";
import Video from "../assets/videos/video.mp4";
import {
  CoverContainer,
  CoverBg,
  VideoBg,
  CoverContent,
  CoverH1,
  CoverP,
  CoverBtnWrapper,
  ArrowForward,
  ArrowRight,
  Button,
} from "../components/_landingpage/CoverElements";

const PageLandingApp = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  return (
    <CoverContainer>
      <CoverBg>
        <VideoBg autoPlay loop muted src={Video} type="video/mp4" />
      </CoverBg>
      <CoverContent>
        <CoverH1>STASIUN METEOROLOGI KELAS 1 JUANDA</CoverH1>
        <CoverP>
          Selamat datang di DAWAI, aplikasi sistem informasi untuk Data Pegawai.
        </CoverP>
        <CoverBtnWrapper>
          <Button
            to="dashboard"
            onMouseEnter={onHover}
            onMouseLeave={onHover}
            primary="true"
            dark="true"
          >
            Login {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </CoverBtnWrapper>
      </CoverContent>
    </CoverContainer>
  );
};

export default PageLandingApp;
