import * as Yup from 'yup';

import { useFormik, Form, FormikProvider } from 'formik';
// material
import {
  Stack,
  TextField,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Container,
  Card,
  CardContent,
  Grid,
  CardHeader,
  Button
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

import axios from 'axios';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { months, monthsKop } from '../../../_mocks_/months';
import { BASE_URL, BASE_URL_FLASK } from '../../../lib/lib.uri';
import { SptTablesReviewSpt } from '.';

export default function FormGenerator() {
  const [dataReport, setDataReport] = useState();
  const [profile, setProfile] = useState();
  const [listProfile, setListProfile] = useState();
  const [dataValues, setDataValues] = useState();
  const [hourly, setHourly] = useState(false);

  const nameUser = useSelector((state) => state.auth.user.name);

  const RegisterSchema = Yup.object().shape({
    name: Yup.string().required('Name required'),
    // dateStart: Yup.string().required('Date required'),
    // dateEnd: Yup.string().required('Date required'),
    duration: Yup.string().required('Duration required'),
    event: Yup.string().required('Event kerja'),
    location: Yup.string().required('Location required'),
    dateCreated: Yup.number().typeError('you must specify a number').required('Date required'),
    monthKop: Yup.string().required('Kop Month required'),
    monthCreated: Yup.string().required('Month Created required')
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      dateStart: '',
      dateEnd: '',
      duration: '',
      event: '',
      location: '',
      creator: '',
      dateCreated: '',
      monthCreated: '',
      dateEvent: '',
      monthKop: '',
      sppd: false
    },
    validationSchema: RegisterSchema,
    onSubmit: () => {
      const body = {
        nama: values.name,
        tugas: values.event,
        lokasi: values.location,
        tanggalBerangkat: values.dateStart,
        tanggalKembali: values.dateEnd,
        durasi: values.duration,
        tanggal: values.dateCreated,
        bulan: values.monthCreated,
        nobulan: values.monthKop,
        sppd: values.sppd,
        tanggalBerlangsung: values.dateEvent,
        jam: hourly,
        creator: nameUser
      };

      axios
        .post(`${BASE_URL_FLASK}spt/request`, body)
        .then((respons) => {
          setDataValues(body);
          setDataReport(respons.data.data);
          setProfile(respons.data.profile);
          setListProfile(respons.data.listProfile);
        })
        .catch((err) => {})
        .finally(() => {
          setSubmitting(false);
        });
    }
  });
  const {
    errors,
    touched,
    values,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldError,
    setSubmitting
  } = formik;
  const btnDownload = () => {
    axios({
      url: `${BASE_URL_FLASK}spt/generator/download`,
      method: 'GET',
      responseType: 'blob' // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.docx');
      document.body.appendChild(link);
      link.click();
    });
    axios
      .post(`${BASE_URL}api/arsiparis/spt/input`, dataValues)
      .then(() => {
        alert('SPT BERHASIL DISIMPAN');
      })
      .catch((err) => {
        alert('SPT GAGAL DISIMPAN, HARAP HUBUNGI ADMIN');
      });
  };

  const hourlyChecked = () => {
    setHourly(!hourly);
  };

  return (
    <FormikProvider value={formik}>
      <Card style={{ marginBottom: 20 }}>
        <CardContent>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                  fullWidth
                  label="Name"
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
                <FormControlLabel
                  control={<Checkbox {...getFieldProps('hourly')} onChange={hourlyChecked} />}
                  label="hourly"
                />
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                  fullWidth
                  label="Duration"
                  {...getFieldProps('duration')}
                  error={Boolean(touched.duration && errors.duration)}
                  helperText={touched.duration && errors.duration}
                />
                <TextField
                  fullWidth
                  label="Location"
                  {...getFieldProps('location')}
                  error={Boolean(touched.location && errors.location)}
                  helperText={touched.location && errors.location}
                />
              </Stack>
              {!hourly && (
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <TextField
                    fullWidth
                    type="date"
                    label=""
                    {...getFieldProps('dateStart')}
                    error={Boolean(touched.dateStart && errors.dateStart)}
                    helperText={touched.dateStart && errors.dateStart}
                  />

                  <TextField
                    fullWidth
                    type="date"
                    label=""
                    {...getFieldProps('dateEnd')}
                    error={Boolean(touched.dateEnd && errors.dateEnd)}
                    helperText={touched.dateEnd && errors.dateEnd}
                  />
                </Stack>
              )}
              {hourly && (
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <TextField
                    fullWidth
                    type="date"
                    label=""
                    {...getFieldProps('dateEvent')}
                    error={Boolean(touched.dateEvent && errors.dateEvent)}
                    helperText={touched.dateEvent && errors.dateEvent}
                  />
                </Stack>
              )}
              <TextField
                fullWidth
                label="Event"
                {...getFieldProps('event')}
                error={Boolean(touched.event && errors.event)}
                helperText={touched.event && errors.event}
              />

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                  fullWidth
                  label="Tanggal Surat Dibuat"
                  {...getFieldProps('dateCreated')}
                  error={Boolean(touched.dateCreated && errors.dateCreated)}
                  helperText={touched.dateCreated && errors.dateCreated}
                />
                <FormControl fullWidth style={{ marginBottom: 10 }}>
                  <InputLabel id="monthChoose">Bulan</InputLabel>
                  <Select
                    labelId="monthChoose"
                    value="Januari"
                    label="Bulan"
                    {...getFieldProps('monthCreated')}
                    error={Boolean(touched.dateCreated && errors.dateCreated)}
                    helperText={touched.dateCreated && errors.dateCreated}
                  >
                    {months.map((month, index) => (
                      <MenuItem key={index} value={month}>
                        {month}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth style={{ marginBottom: 10 }}>
                  <InputLabel id="monthKop">Bulan Romawi</InputLabel>
                  <Select
                    labelId="monthKop"
                    value=""
                    label="Bulan"
                    {...getFieldProps('monthKop')}
                    error={Boolean(touched.monthKop && errors.monthKop)}
                    helperText={touched.monthKop && errors.monthKop}
                  >
                    {monthsKop.map((month, index) => (
                      <MenuItem key={index} value={month}>
                        {month}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {!hourly && (
                  <FormControlLabel
                    control={<Checkbox {...getFieldProps('sppd')} />}
                    label="SPPD"
                  />
                )}
              </Stack>

              <LoadingButton
                fullWidth
                id="btnCreateSpt"
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                Create SPT
              </LoadingButton>
            </Stack>
          </Form>
        </CardContent>
      </Card>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} md={6} xl={6}>
          {listProfile !== undefined &&
            listProfile.map((profile) => (
              <Card key={profile} style={{ marginBottom: 20 }}>
                <CardHeader title={profile.nama} />
                <CardContent>
                  <SptTablesReviewSpt data={profile} />
                </CardContent>
              </Card>
            ))}
        </Grid>
        <Grid item xs={12} md={6} xl={6}>
          {dataReport !== undefined && (
            <Card>
              <CardHeader title="Data Review" />
              <CardContent>
                <SptTablesReviewSpt data={dataReport} />
                <Button onClick={btnDownload}>DOWNLOAD</Button>
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>
    </FormikProvider>
  );
}
