import * as Yup from "yup";
import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { useFormik, Form, FormikProvider } from "formik";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
// import { useHistory, useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

// ----------------------------------------------------------------------

import { useHistory } from "react-router-dom";
import {
  phoneRegExp,
  usernameRegExp,
  nameRegExp,
} from "../../../utils/utils.regex";
import { registerUserAction } from "../../../redux/actions/redux.action.auth";
import { BASE_URL } from "src/lib/lib.uri";

export default function RegisterForm() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [roleUser, setRoleUser] = useState([]);

  useEffect(() => {
    fetch(`${BASE_URL}api/users/role`)
      .then((response) => response.json())
      .then((data) => {
        setRoleUser(data);
      })
      .catch((error) => console.error(error));
  }, []);

  const RegisterSchema = Yup.object().shape({
    name: Yup.string()
      .matches(nameRegExp, "Username Not valid")
      .required("Name required"),
    username: Yup.string()
      .required("Username required")
      .min(8, "Too Short!")
      .max(50, "Too Long!")
      .matches(usernameRegExp, "Username Not valid"),
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    numberphone: Yup.string()
      .required("Phone Number required")
      .matches(phoneRegExp, "Phone number is not valid"),
    unit: Yup.string().required("Choose unit kerja"),
    nip: Yup.string()
      .min(18, "Not Valid!")
      .max(18, "Not Valid")
      .required("nip is required"),
    password: Yup.string()
      .min(8, "Too Short!")
      .required("Password is required"),
    password2: Yup.string()
      .required("Password is required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      name: "",
      nip: "",
      unit: "",
      email: "",
      numberphone: "",
      password: "",
      password2: "",
    },
    validationSchema: RegisterSchema,
    onSubmit: () => {
      registerUserAction(values)
        .then((res) => {
          dispatch({
            type: "USER_REGISTER",
          });
          history.push("/login", {
            options: {
              state: "Check your email for verification",
              replace: true,
            },
          });
        })
        .catch((err) => {
          setFieldError("email", err.response.data.email);
          setFieldError("username", err.response.data.username);
          setFieldError("numberphone", err.response.data.numberphone);
          setFieldError("name", err.response.data.name);
          setFieldError("nip", err.response.data.nip);
          setFieldError("unit", err.response.data.unit);
          setFieldError("password", err.response.data.password);
          history.push("/register");
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const {
    errors,
    touched,
    values,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldError,
    setSubmitting,
  } = formik;
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <TextField
              fullWidth
              label="Name"
              {...getFieldProps("name")}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />

            <TextField
              fullWidth
              label="Username"
              {...getFieldProps("username")}
              error={Boolean(touched.username && errors.username)}
              helperText={touched.username && errors.username}
            />
          </Stack>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <TextField
              fullWidth
              autoComplete="nip"
              type="number"
              label="NIP"
              {...getFieldProps("nip")}
              error={Boolean(touched.nip && errors.nip)}
              helperText={touched.nip && errors.nip}
            />
            <TextField
              fullWidth
              select
              autoComplete="unit"
              label="Unit"
              {...getFieldProps("unit")}
              error={Boolean(touched.unit && errors.unit)}
              helperText={touched.unit && errors.unit}
            >
              {roleUser.map((roles) => (
                <MenuItem key={roles.id} value={roles.name}>
                  {roles.name}
                </MenuItem>
              ))}
            </TextField>
          </Stack>

          <TextField
            fullWidth
            autoComplete="email"
            type="email"
            label="Email address"
            {...getFieldProps("email")}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          <TextField
            fullWidth
            autoComplete="Number Phone"
            type="number"
            label="Number Phone"
            {...getFieldProps("numberphone")}
            error={Boolean(touched.numberphone && errors.numberphone)}
            helperText={touched.numberphone && errors.numberphone}
          />
          <TextField
            fullWidth
            type={showPassword ? "text" : "password"}
            label="Password"
            {...getFieldProps("password")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={() => setShowPassword((prev) => !prev)}
                  >
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
          <TextField
            fullWidth
            type={showPassword2 ? "text" : "password"}
            label="Password Confirmation"
            {...getFieldProps("password2")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={() => setShowPassword2((prev) => !prev)}
                  >
                    <Icon icon={showPassword2 ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password2 && errors.password2)}
            helperText={touched.password2 && errors.password2}
          />
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Register
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
