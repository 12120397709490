import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
/* eslint-disable import/no-unresolved */
import { React } from "react";

// import { Provider } from 'react-redux';

// eslint-disable-next-line camelcase
import jwt_decode from "jwt-decode";
import { useSelector } from "react-redux";
import PageLogbooks from "src/pages/logbooks/page.logbooks";
import PageInputLogbooks from "src/pages/logbooks/page.logbooks.input";
import RedirectNative from "../components/atoms/Redirect/redirectNative";
import PageSptGenerator from "../pages/page.sptGenerator";
import PageDocsUsers from "../pages/page.docsUsers";
import store from "../redux/store/redux.store";

import {
  setCurrentUserAction,
  logoutUserAction,
} from "../redux/actions/redux.action.auth";

import setAuthToken from "../lib/lib.authToken";
// import Register from '../pages/Register';
import PageLogin from "../pages/auth/page.auth.login";
import PageRegister from "../pages/auth/page.auth.register";
import PageLandingApp from "../pages/page.landingApp";
import DashboardLayout from "../layouts/dashboard/index";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
import PageDocsUser from "../pages/page.docsUser";
import PageProfile from "../pages/page.profile";
import PageJamPelajaran from "src/pages/jampelajaran/page.input";
import PageMonitorJamPelajaran from "src/pages/jampelajaran/page.monitor";
import PageDashboard from "src/pages/page.dashboard";

// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Decode token and get user info and exp
  const decoded = jwt_decode(token);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUserAction(decoded));
  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUserAction());
    // Redirect to login
    // window.location.href = './login';
  }
}

const PrivateRoute = ({ children, ...rest }) => {
  const auth = useSelector((state) => state.auth);
  const isLoggedIn = auth.isAuthenticated;
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
const RouteAuth = ({ path, element }) => (
  <Route path={path}>
    <LogoOnlyLayout Element={element} />
  </Route>
);

const RouteDash = ({ path, element }) => (
  <Route path={path}>
    <DashboardLayout Element={element} />
  </Route>
);

const RoutePrivate = ({ path, element }) => (
  <PrivateRoute path={path}>
    <DashboardLayout Element={element} />
  </PrivateRoute>
);

const RouteRedirect = ({ path, url }) => (
  <Route path={path}>
    <RedirectNative url={url} />
  </Route>
);

export default function Routers() {
  return (
    <Router basename="/arsip/">
      <Switch>
        <RouteAuth path="/login" element={PageLogin} />
        <RouteAuth path="/register" element={PageRegister} />
        <RouteAuth exact path="/" element={PageLandingApp} />
        <RoutePrivate path="/profile" element={PageProfile} />
        <RoutePrivate path="/dashboard" element={PageDashboard} />
        <RoutePrivate exact path="/logbooks" element={PageLogbooks} />
        <RoutePrivate path="/logbooks/input" element={PageInputLogbooks} />
        <RoutePrivate path="/docsuser" element={PageDocsUser} />
        <RoutePrivate path="/docsusers" element={PageDocsUsers} />
        <RoutePrivate path="/jampelajaran" element={PageJamPelajaran} />
        <RoutePrivate path="/monitorjp" element={PageMonitorJamPelajaran} />
        <RoutePrivate path="/sptgenerator" element={PageSptGenerator} />
      </Switch>
    </Router>
  );
}
