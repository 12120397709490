import React, { useEffect, useState } from "react";
import {
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import axios from "axios";
import { DropzoneArea } from "material-ui-dropzone";
import { useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { BASE_URL } from "../lib/lib.uri";
import MuiDataTables from "../components/molecules/MuiDatatables/index";
import Page from "../components/molecules/Page";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",

  boxShadow: 24,
  borderRadius: 5,
  p: 4,
};
const list = ["Document", "Description"];

export default function PageDocsUsers() {
  const [open, setOpen] = React.useState(false);
  const [nameDoc, setNameDoc] = React.useState();
  const [pathDoc, setPathDoc] = React.useState();
  const [userAlreadyUploaded, setUserAlreadyUploaded] = React.useState([]);
  const [userWantToGet, setUserWantToGet] = React.useState("");
  const [documentUser, setDocumentsUser] = React.useState();

  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    axios
      .get(`${BASE_URL}api/arsiparis/documents/list/uploaded`)
      .then((resp) => {
        setUserAlreadyUploaded(resp.data);
      });
    return () => {};
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const options = {
    selectableRows: true,
    onRowClick: (rowData, rowState) => {
      setNameDoc(rowData[0]);
      setPathDoc(rowData[1]);
      handleOpen();
    },
  };

  const handleChange = (e) => {
    const username = e.target.value;
    setUserWantToGet(username);
    axios
      .get(`${BASE_URL}api/arsiparis/documents/data/table/${username}`)
      .then((resp) => {
        setDocumentsUser(resp.data);
      });
  };

  const downloadFile = () => {
    axios({
      url: `${BASE_URL}api/arsiparis/documents/download/${userWantToGet}/${nameDoc}`,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.pdf");
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <Page title="Data | Juanda">
      <Container maxWidth="xl" style={{ padding: 10 }}>
        <FormControl fullWidth style={{ marginBottom: 10 }}>
          <InputLabel id="demo-simple-select-label">User</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={userWantToGet}
            label="User"
            onChange={handleChange}
          >
            {userAlreadyUploaded.map((userDocument, index) => (
              <MenuItem key={index} value={userDocument.username}>
                {userDocument.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <div>
          {list.length > 0 && (
            <MUIDataTable
              title="DATA ARSIP"
              data={documentUser}
              columns={list}
              options={options}
            />
          )}
        </div>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div>
              <h1>DOWNLOAD DOCUMENT</h1>
              <div>
                <button onClick={downloadFile}>Download Existing File</button>
              </div>
            </div>
          </Box>
        </Modal>
      </Container>
    </Page>
  );
}
MuiDataTables.propTypes = {
  dataSet: PropTypes.array,
};
