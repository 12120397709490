/* eslint-disable import/no-unresolved */
import * as Yup from 'yup';

import { useFormik, Form, FormikProvider } from 'formik';
// material
import {
  Stack,
  TextField,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Container,
  Card,
  CardContent,
  Grid,
  CardHeader,
  Button
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BASE_URL } from 'src/lib/lib.uri';

export default function PageInputLogbooks() {
  const [aloptama, setAloptama] = useState({});
  useEffect(() => {
    axios.get(`${BASE_URL}api/constants/instrumentsJuanda`).then((result) => {
      setAloptama(result.data.value[0].data);
    });
    return () => {};
  }, []);

  const nameUser = useSelector((state) => state.auth.user.name);

  const RegisterSchema = Yup.object().shape({
    name: Yup.string().required('Name required'),
    value: Yup.string().required('Value required'),
    date: Yup.string().required('Value required'),
    aloptama: Yup.string()
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      date: '',
      value: '',
      aloptama: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: (values) => {
      axios
        .post(`${BASE_URL}api/logbooks/input`, values)
        .then((result) => {})
        .finally(() => {
          setSubmitting(false);
          alert('success');
        });
    }
  });
  const {
    errors,
    touched,
    values,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldError,
    setSubmitting
  } = formik;
  return (
    <FormikProvider value={formik}>
      <Card style={{ marginBottom: 20 }}>
        <CardContent>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                  fullWidth
                  label="Name"
                  placeholder={nameUser}
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
              </Stack>
              <TextField
                fullWidth
                type="date"
                label=""
                {...getFieldProps('date')}
                error={Boolean(touched.date && errors.date)}
                helperText={touched.date && errors.date}
              />
              <TextField
                fullWidth
                label="Value"
                {...getFieldProps('value')}
                error={Boolean(touched.value && errors.value)}
                helperText={touched.value && errors.value}
              />
              <FormControl fullWidth style={{ marginBottom: 10 }}>
                <InputLabel id="Aloptama">Aloptama</InputLabel>
                <Select
                  labelId="Aloptama"
                  value=""
                  label="Aloptama"
                  {...getFieldProps('aloptama')}
                  error={Boolean(touched.dateCreated && errors.dateCreated)}
                  helperText={touched.dateCreated && errors.dateCreated}
                >
                  {aloptama.length > 0 &&
                    aloptama.map((month, index) => (
                      <MenuItem key={index} value={month}>
                        {month}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <LoadingButton
                fullWidth
                id="btnCreateSpt"
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                Input Logbooks
              </LoadingButton>
            </Stack>
          </Form>
        </CardContent>
      </Card>
    </FormikProvider>
  );
}
