/* eslint-disable import/no-unresolved */

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, Typography } from "@mui/material";

import { LogbooksColumnConfig } from "src/components/_dashboard/logbooks/LogbooksColumnConfig";
import { BASE_URL } from "../../lib/lib.uri";
import MuiDataTables from "../../components/molecules/MuiDatatables/index";
import Page from "../../components/molecules/Page";
import { useSelector } from "react-redux";

export default function PageLogbooks() {
  const [tableData, setTableData] = useState({});
  const user = useSelector((state) => state.auth.user);
  useEffect(() => {
    console.log(`${BASE_URL}api/logbooks/unit/${user.unit}`);
    axios
      .get(`${BASE_URL}api/logbooks/unit/${user.unit}`)
      .then((result) => {
        setTableData(result.data);
        console.log(result);
      })
      .catch((err) => console.log(err));
    return () => {};
  }, []);

  return (
    <Page title="Logbook | Juanda">
      <Container maxWidth="xl" style={{ padding: 10 }}>
        <div>
          {tableData.length > 0 ? (
            <MuiDataTables
              dataSet={tableData}
              title="Logbooks"
              columns={LogbooksColumnConfig("observer")}
            />
          ) : (
            <Typography>Logbooks is empty</Typography>
          )}
        </div>
      </Container>
    </Page>
  );
}
