/* eslint-disable import/no-unresolved */
import Box from "@mui/material/Box";
import React from "react";
import { useFormik, Form, FormikProvider } from "formik";
// material
import { Stack, TextField, Card, CardContent, Modal } from "@mui/material";
import { LoadingButton } from "@mui/lab";

// ----------------------------------------------------------------------

import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BASE_URL } from "src/lib/lib.uri";
import MUIDataTable from "mui-datatables";
import ModalForTable from "src/components/atoms/Model";

const style = {
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
};

const inputStyle = {
  marginRight: "20px",
  border: "none",
  padding: "10px 20px",
  borderRadius: "10px",
  color: "#fff",
  cursor: "pointer",
  transition: "background 0.2s ease-in-out",
  width: "100%",
  maxWidth: "100%",
  color: "#444",
  padding: "5px",
  background: "#fff",
  borderRadius: "10px",
  border: "1px solid #555",
};
const list = ["Document", "Date Time", "JP", "Penyelenggara"];

export default function PageJamPelajaran() {
  const user = useSelector((state) => state.auth.user);
  const [uploadFile, setUploadFile] = React.useState();
  const [uploaded, setUploaded] = React.useState(false);
  const [title, setTitle] = React.useState();
  const [jamPelajaran, setJamPelajaran] = React.useState();
  const [penyelenggara, setPenyelenggara] = React.useState();
  const [noSertifikat, setNoSertifikat] = React.useState();
  const [date, setDate] = React.useState();
  const [documentUser, setDocumentsUser] = React.useState();
  const [nameDoc, setNameDoc] = React.useState();
  const [jpUser, setJpUser] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [idDoc, setIdDoc] = React.useState();

  const onFileChange = (event) => {
    setUploadFile(event.target.files[0]);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const options = {
    selectableRows: true,
    onRowClick: (rowData, rowState) => {
      setNameDoc(rowData[0]);
      handleOpen();
    },
  };
  const clearValues = () => {
    setUploadFile("");
    setTitle("");
    setJamPelajaran("");
    setPenyelenggara("");
    setNoSertifikat("");
    setDate("");
    setNameDoc("");
    // You don't need to clear user-related states such as user, documentUser, and jpUser
  };
  const onFileUpload = () => {
    const formData = new FormData();
    if (uploadFile !== undefined) {
      formData.append("file", uploadFile, uploadFile.name);
      formData.append("title", title);
      formData.append("jamPelajaran", jamPelajaran);
      formData.append("penyelenggara", penyelenggara);
      formData.append("noSertifikat", noSertifikat);
      formData.append("username", user.username);
      formData.append("name", user.name);
      formData.append("date", date);

      axios
        .post(`${BASE_URL}api/arsiparis/workshops/input`, formData)
        .then((resp) => {
          if (resp.data.status === "success") {
            alert(resp.data.text);
            setUploaded((prevState) => !prevState); // Toggle the state
            clearValues();
            console.log(title);
          } else {
            alert(resp.data.text);
            setUploaded((prevState) => !prevState); // Toggle the state
            clearValues();
            console.log(title);
          }
          setUploaded((prevState) => !prevState); // Toggle the state
        });
    } else {
      alert("Choose a file!");
    }
  };
  const onEditFile = () => {
    console.log("edit");
    const formData = new FormData();

    formData.append("title", title);
    formData.append("jamPelajaran", jamPelajaran);
    formData.append("penyelenggara", penyelenggara);
    formData.append("noSertifikat", noSertifikat);
    formData.append("username", user.username);
    formData.append("name", user.name);
    formData.append("date", date);
    formData.append("id", idDoc);

    axios
      .post(`${BASE_URL}api/arsiparis/workshops/edit`, formData)
      .then((resp) => {
        clearValues();
        setUploaded((prevState) => !prevState); // Toggle the state
        setEdit(false);
      })
      .catch((err) => console.log(err));
  };
  const handleDeleteDocument = () => {
    axios
      .delete(
        `${BASE_URL}api/arsiparis/workshops/delete/${user.username}/${nameDoc}`
      )
      .then((response) => {
        if (response.data.status === "success") {
          alert("Document deleted successfully!");

          setUploaded((prevState) => !prevState); // Toggle the state
          handleClose();
        } else {
          alert(response.data.message || "Error deleting the document");
        }
      })
      .catch((error) => {
        console.log(error);
        alert("There was an error while deleting the document");
      });
  };
  const handleEditDocument = () => {
    setEdit(true);
    console.log("ok");
    axios
      .get(
        `${BASE_URL}api/arsiparis/workshops/userdoc/${user.username}/${nameDoc}`
      )
      .then((response) => {
        const doc = response.data.value[0];
        setTitle(doc.title);
        setJamPelajaran(doc.jampelajaran);
        setPenyelenggara(doc.penyelenggara);
        setNoSertifikat(doc.no_sertifikat);
        setDate(doc.datetime);
        setIdDoc(doc._id);
      })
      .catch((error) => {});
    handleClose();
  };

  useEffect(() => {
    axios
      .get(`${BASE_URL}api/arsiparis/workshops/user/${user.username}`)
      .then((resp) => {
        let documentWorkshop = [];
        resp.data.value.forEach((item) => {
          documentWorkshop.push([
            item.title,
            item.datetime,
            item.jampelajaran,
            item.penyelenggara,
          ]);
        });

        let jp = resp.data.value.reduce((accumulator, current) => {
          const year = new Date(current.datetime).getFullYear();
          if (year === 2023) {
            return accumulator + parseInt(current.jampelajaran, 10);
          }
          return accumulator;
        }, 0);

        setJpUser(jp);
        setDocumentsUser(documentWorkshop);
      });
  }, [uploaded]);
  return (
    <Card style={{ marginBottom: 20 }}>
      <CardContent>
        <Stack spacing={3}>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <TextField
              fullWidth
              label="Judul Kegiatan"
              placeholder="Masukkan Judul"
              required
              InputLabelProps={{ shrink: true }}
              onChange={(event) => setTitle(event.target.value)}
              value={title}
            />
          </Stack>
          <TextField
            fullWidth
            type="number"
            label="Jam Pelajaran"
            InputLabelProps={{ shrink: true }}
            placeholder="Masukkan Jam Pelajaran"
            required
            onChange={(event) => setJamPelajaran(event.target.value)}
            value={jamPelajaran}
          />
          <TextField
            fullWidth
            label="Penyelenggara"
            placeholder="Masukkan Penyelenggara"
            InputLabelProps={{ shrink: true }}
            required
            onChange={(event) => setPenyelenggara(event.target.value)}
            value={penyelenggara}
          />
          <TextField
            fullWidth
            label="Nomor Sertifikat"
            placeholder="Masukkan Nomor Sertifikat"
            InputLabelProps={{ shrink: true }}
            required
            onChange={(event) => setNoSertifikat(event.target.value)}
            value={noSertifikat}
          />
          <TextField
            fullWidth
            type="date"
            label=""
            InputLabelProps={{ shrink: true }}
            required
            onChange={(event) => setDate(event.target.value)}
            value={date}
          />
          {!edit && (
            <Box sx={style}>
              <div>
                <input type="file" onChange={onFileChange} style={inputStyle} />
              </div>
              {/* <DropzoneArea onChange={handleChange} /> */}
            </Box>
          )}
          <LoadingButton
            fullWidth
            id="btnCreateSpt"
            size="large"
            type="submit"
            variant="contained"
            color={edit ? "info" : "primary"}
            onClick={edit ? onEditFile : onFileUpload}
          >
            {edit ? "Edit" : "Input JP"}
          </LoadingButton>
        </Stack>
        <div style={{ marginTop: "50px" }}>
          {list.length > 0 && (
            <MUIDataTable
              title="DATA ARSIP"
              data={documentUser}
              columns={list}
              options={options}
            />
          )}
        </div>

        <ModalForTable open={open} onClose={handleClose}>
          <div>
            <h1>DOCUMENT</h1>
            <div>
              <button
                onClick={handleDeleteDocument}
                style={{ margin: 2, padding: 5 }}
              >
                Delete Existing File
              </button>
              <button
                onClick={handleEditDocument}
                style={{ margin: 2, padding: 5 }}
              >
                Edit Existing File
              </button>
            </div>
          </div>
        </ModalForTable>
      </CardContent>
    </Card>
  );
}
