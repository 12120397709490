/* eslint-disable arrow-body-style */
import axios from 'axios';

// eslint-disable-next-line import/no-unresolved
import { BASE_URL } from 'src/lib/lib.uri';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import { GET_ERRORS, SET_CURRENT_USER, USER_LOADING } from './redux.action.types';

import setAuthToken from '../../lib/lib.authToken';

// Register User
export const registerUserAction = (userData, history) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${BASE_URL}api/users/register`, userData)
      .then((res) => {
        resolve(res);
      }) // re-direct to login on successful register
      .catch((err) => {
        reject(err);
      });
  });
};
// Login - get user token
export const loginUserAction = (userData) => (dispatch) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${BASE_URL}api/users/login`, userData)
      .then((res) => {
        // Save to localStorage
        // Set token to localStorage
        const { token } = res.data;
        localStorage.setItem('jwtToken', token);
        // Set token to Auth header
        setAuthToken(token);
        // Decode token to get user data
        const decoded = jwt_decode(token);
        // Set current user
        dispatch(setCurrentUserAction(decoded));
        resolve(true);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
// Set logged in user
export const setCurrentUserAction = (decoded) => ({
  type: SET_CURRENT_USER,
  payload: decoded
});
// User loading
export const setUserLoadingAction = () => ({
  type: USER_LOADING
});
// Log user out
export const logoutUserAction = (history) => (dispatch) => {
  // Remove token from local storage
  localStorage.removeItem('jwtToken');
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUserAction({}));
};
