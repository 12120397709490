import React from 'react';
import Plot from 'react-plotly.js';
import { Card, CardHeader, Box } from '@mui/material';

const PlotlyChart = ({ dataPlot, title, minHeight }) => {

    const transformedData = Object.entries(dataPlot).map(([year, jampelajaran]) => ({
        year: parseInt(year, 10),
        jampelajaran
    }));
    const transformDataPlot = (formattedData) => {
        if (!Array.isArray(formattedData)) {
            console.error('Expected an array for formattedData', formattedData);
            return []; // Kembali array kosong atau nilai default lain jika input bukan array
        }

        return [{
            x: formattedData.map(item => item.year.toString()),
            y: formattedData.map(item => item.jampelajaran),
            type: 'bar',
            marker: { color: '#757ce8' },
            name: "JP"

        }];
    };



    return (
        <Card>
            <Box sx={{ p: 3, pb: 1 }} minHeight={minHeight} dir="ltr">
                <Plot
                    data={transformDataPlot(transformedData)} // Pastikan transformDataPlot sudah mendapat data yang benar
                    layout={{
                        autosize: true,
                        showlegend: true,
                        xaxis: {
                            showgrid: true,
                            zeroline: true,
                            showline: false,
                            mirror: 'ticks',
                            gridcolor: '#bdbdbd',
                            zerolinecolor: '#969696',
                            zerolinewidth: 4,
                            linecolor: '#636363',
                            linewidth: 6,
                            tickvals: [2023, 2024],
                            ticktext: ['2023', '2024'],
                        },
                        yaxis: {
                            automargin: true,
                            // Menambahkan range untuk memastikan nilai 0 tetap terlihat jelas
                            range: [-1, Math.max(...transformedData.map(item => item.jampelajaran)) + 1] // Sesuaikan range sesuai kebutuhan
                        }
                    }}
                    config={{
                        displayModeBar: false
                    }}
                    style={{ width: '100%', height: '100%' }}
                    useResizeHandler
                />

            </Box>
        </Card>
    );
};

export default PlotlyChart;
