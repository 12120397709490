import { Backdrop, CircularProgress } from '@mui/material';
import React, { useEffect } from 'react';

function RedirectNative({ url }) {
  useEffect(() => {
    window.location.replace(url);
  }, []);
  return <div />;
}

export default RedirectNative;
