import React from 'react';
import { SptFormGenerator } from '../components/_dashboard/spt';

function PageSptGenerator() {
  return (
    <div>
      <SptFormGenerator />
    </div>
  );
}

export default PageSptGenerator;
