export const LogbooksColumnConfig = (role) => {
  if (role === "observer") {
    return [
      {
        name: "Name",
        options: {
          filter: true,
        },
      },
      {
        name: "DateTime",
        options: {
          filter: true,
        },
      },
      {
        name: "Value",
        options: {
          filter: true,
        },
      },
    ];
  }
};
