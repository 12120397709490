import { SET_CURRENT_USER, USER_LOADING, USER_REGISTER } from '../actions/redux.action.types';

const isEmpty = require('is-empty');

const initialState = {
  isAuthenticated: false,
  user: {},
  loading: false,
  register: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload
      };
    case USER_LOADING:
      return {
        ...state,
        loading: true
      };
    case USER_REGISTER:
      return {
        ...state,
        register: true
      };
    default:
      return state;
  }
};
