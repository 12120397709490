import { Alert, Stack } from '@mui/material';
import React from 'react';

function AlertMui({ status, message }) {
  return (
    <Stack sx={{ mb: 5 }}>
      <Alert severity={status}>{message}</Alert>
    </Stack>
  );
}

export default AlertMui;
